<template>
    <!-- 管理员- 报告管理 -->
    <div class="totalReport wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
            </div>
            <div class="formInline-right flex-align-between">
                <div class="form-inline-item csp">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span class="csp" @click="create">创建报告</span>
                </div>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                </el-table-column>
                <el-table-column prop="title" label="报告名称" align="center">
                </el-table-column>
                <el-table-column prop="type" label="报告类型" align="center">
                    <template slot-scope="scope">
                        {{ typeOption[scope.row.type].label }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="生成时间" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="240">
                    <template slot-scope="scope">
                        <el-button @click="viewFile(scope.row)" type="text" size="small">查看
                        </el-button>
                        <el-button @click="editReport(scope.row)" type="text" size="small">编辑
                        </el-button>
                        <el-button @click="release(scope.row)" type="text" size="small">发布
                        </el-button>
                        <el-button type="text" size="small" @click="deleteProjectFile(scope.row)">删除
                        </el-button>
                        <el-button @click="editFile(scope.row)" type="text" size="small">编辑报告
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10, 20, 30]" @pagination="handlePageChange">
            </pagination>
        </div>
    </div>
</template>

<script>
import upload from '../components/upload-file.vue';
import {
    cloneDeep
} from 'lodash/lang'
import Template from '../../td_test/template.vue';

export default {
    components: {
        upload,
        Template
    },
    data() {
        return {
            activeName: "",
            rules: {
                name: {
                    required: true,
                    message: "请输入文件名称",
                    trigger: "blur"
                },
            },
            tableData: [],
            pages: { //分页
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            showCreateDialog: false,
            createForm: {
                name: undefined,
                identifier: undefined,
                type: 4,
            },
            dialogTitle: '上传周报',
            editIdentifierList: [],
            typeOption: [
                { label: '' }
            ],
        };
    },
    methods: {
        indexMethod(index) {
            return this.pages.currentPage > 1 ?
                (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                index + 1
        },
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            this.getReportList();
        },
        // 获取报告列表
        async getReportList() {
            let pramas = {
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportList(pramas);
            console.log('列表数据', resData.data);
            this.tableData = resData.data.records
            this.pages.total = resData.data.total
        },
        // 获取报告类型Option
        async getReportOption() {
            let pramas = {
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportOption(pramas);
            console.log('选项数据', resData.data);
            this.typeOption = resData.data.type
        },
        // 创建报告
        create() {
            this.$router.push(`/manager/report/create/${this.$store.state.user.currentProject.id}?breadNumber=4`)
        },
        // 查看报告
        viewFile(row) {
            this.$router.push({
                path: `/manager/report/view_kimi?breadNumber=4`,
                query: {
                    reportId: row.id,
                    reportType: row.type,
                    title: row.title,
                    isEdit: false
                }
            })
        },
        // 编辑报告
        editFile(row) {
            this.$router.push({
                path: `/manager/report/view_kimi?breadNumber=4`,
                query: {
                    reportId: row.id,
                    reportType: row.type,
                    title: row.title,
                    isEdit: true
                }
            })
        },
        release(row) {
            this.$confirm(`是否发布名为“${row.title}”的报告?`, "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                let params = {
                    id: row.id,
                    status: row.status
                }
                this.$Api.Project.getReportPublish(params).then((resData) => {
                    if (resData.code == 200) {
                        this.$message({
                            message: "发布成功",
                            type: "success"
                        });
                        this.getReportList();
                    } else {
                        this.$message.error('发布失败', resData.msg);
                    }
                })
            }).then(response => { }).catch(() => {
                this.$message({
                    type: "info",
                    message: "取消发布"
                });
            });
        },
        // 删除
        deleteProjectFile(row) {
            this.$confirm(`是否删除名为“${row.title}”的报告？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let params = {
                        id: row.id
                    }
                    console.log(params);
                    this.$Api.Project.delReport(params).then((resData) => {
                        console.log(resData);
                        if (resData.code == 200) {
                            this.$message({
                                message: "删除成功",
                                type: "success"
                            });
                            this.getReportList();
                        } else {
                            this.$message.error(resData.msg);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消删除"
                    });
                });
        },
        // 编辑报告(重新生成)
        editReport(row) {
            this.$router.push({
                path: `/manager/report/edit/${row.id}?breadNumber=4`,
            })
        }
    },
    mounted() {
        this.getReportList();
        this.getReportOption();
        Vue.set(this.createForm, 'projectId', this.$store.state.user.currentProject.id);
    },
}
</script>

<style lang="less" scoped>
@import "../../manage/less/table.less";

.totalReport {
    padding: 20px 25px;
    box-sizing: border-box;

    /deep/ .el-dialog {
        border-radius: 10px;

        .el-dialog__header {
            border-bottom: 1px solid #E4E4E4;
            padding: 15px 31px 10px;
        }

        .el-dialog__title {
            font-size: 15px;
            color: #333;

            &::before {
                content: '';
                width: 5px;
                height: 17px;
                background-color: #01A2FD;
                display: inline-block;
                margin-right: 14px;
                vertical-align: -3px;
                border-radius: 3px;
            }
        }

        .el-dialog__headerbtn {
            top: 15px;
        }

        .btns-box {
            text-align: center;
        }

        .submit {
            background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
            border-radius: 25px;
            color: #fff;
            line-height: 35px;
            padding: 0 63px;
            cursor: pointer;
            border: 0;

            &:hover {
                background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
            }
        }
    }

    .table_section {
        margin-top: 15px;
    }
}
</style>